import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 解决导航栏或者底部导航tabBar中的vue-router在3.0版本以上频繁点击菜单报错的问题。
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'Manager',
    component: () => import('../views/Manager.vue'),
    redirect: '/home',  // 重定向到主页
    children: [
      { path: '403', name: 'NoAuth', meta: { name: '无权限' }, component: () => import('../views/manager/403') },
      { path: 'home', name: 'Home', meta: { name: 'Home' }, component: () => import('../views/manager/Home') },
      { path: 'admin', name: 'Admin', meta: { name: 'Admin Information' }, component: () => import('../views/manager/Admin') },
      { path: 'user', name: 'User', meta: { name: 'Employee Information' }, component: () => import('../views/manager/Employee') },
      { path: 'adminPerson', name: 'AdminPerson', meta: { name: 'Personal Info' }, component: () => import('../views/manager/AdminPerson') },
      { path: 'employeePerson', name: 'EmployeePerson', meta: { name: 'Personal Info' }, component: () => import('../views/manager/EmployeePerson') },
      { path: 'password', name: 'Password', meta: { name: 'Change Password' }, component: () => import('../views/manager/Password') },
      { path: 'notice', name: 'Notice', meta: { name: 'Notice' }, component: () => import('../views/manager/Notice') },
      { path: 'deadline', name: 'Deadline', meta: { name: 'Deadline' }, component: () => import('../views/manager/Deadline') },
      { path: 'department', name: 'Department', meta: { name: 'Department' }, component: () => import('../views/manager/Department') },
      { path: 'diskFiles', name: 'DiskFiles', meta: { name: 'All Files' }, component: () => import('../views/manager/DiskFiles') },
      { path: 'share', name: 'Share', meta: { name: 'Share' }, component: () => import('../views/manager/Share') },
      { path: 'trash', name: 'Trash', meta: { name: 'Trash' }, component: () => import('../views/manager/Trash') },
      { path: 'financial', name: 'Financial', meta: { name: 'Financial' }, component: () => import('../views/manager/Financial') },
      { path: 'financeapplyhis',name:'Financeapplyhis', meta: { name: 'Financial Application' }, component: () => import('../views/manager/Financeapplyhis') },
      { path: 'financeapply', name: 'Financeapply', meta: { name: 'Financial Approval' }, component: () => import('../views/manager/Financeapply') },
      { path: 'worktimehis',name:'Worktimehis', meta: { name: 'Work Hours Report' }, component: () => import('../views/manager/Worktimehis') },
      { path: 'worktime', name: 'Worktime', meta: { name: 'Work Hours Approval' }, component: () => import('../views/manager/Worktime') },
      { path: 'weekwork', name: 'WeekWork', meta: { name: 'Work Hours Summary' }, component: () => import('../views/manager/Weekwork') },
      { path: 'allFiles', name: 'AllFiles', meta: { name: 'Cloud' }, component: () => import('../views/manager/AllFiles') },

    ]
  },
  {
    path: '/front',
    name: 'Front',
    component: () => import('../views/Front.vue'),
    children: [
      { path: 'home', name: 'Home', meta: { name: 'Home' }, component: () => import('../views/front/Home') },
      { path: 'person', name: 'Person', meta: { name: 'Personal info' }, component: () => import('../views/front/Person') },
      { path: 'openShare', name: 'OpenShare', meta: { name: 'Share' }, component: () => import('../views/front/OpenShare') },
    ]
  },
  { path: '/login', name: 'Login', meta: { name: '登录' }, component: () => import('../views/Login.vue') },
  { path: '/adminlogin', name: 'Adminlogin', meta: { name: '管理登录' }, component: () => import('../views/Adminlogin') },
  { path: '*', name: 'NotFound', meta: { name: '无法访问' }, component: () => import('../views/404.vue') },
  { path: '/liuying', name: 'Liuying', meta: { name: '流萤' }, component: () => import('../views/liuying.vue') },
  { path: '/mainhome', name: 'Mainhome', meta: { name: '主页' }, component: () => import('../views/mainhome') },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 注：不需要前台的项目，可以注释掉该路由守卫
// 路由守卫
// router.beforeEach((to ,from, next) => {
//   let user = JSON.parse(localStorage.getItem("xm-user") || '{}');
//   if (to.path === '/') {
//     if (user.role) {
//       if (user.role === 'USER') {
//         next('/front/home')
//       } else {
//         next('/home')
//       }
//     } else {
//       next('/login')
//     }
//   } else {
//     next()
//   }
// })

export default router
